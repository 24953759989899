import React, { useState, useEffect } from 'react'
import { navigate } from "gatsby"
import Modal from './modal'
import { StaticImage } from 'gatsby-plugin-image'
//import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const DirectSelling = () => {
  useEffect(() => {
      if (window.location.href.indexOf('#directselling') !== -1) {
        console.log('use effect in scores');
        navigate('/#directselling');
      }
  }, []);

  return (
            <section id='directselling'>
        <div className='row work'>
          <div className='two columns header-col'>
            <h1>
              <span>Scores</span>
            </h1>
          </div>
          <div className='ten columns main-col'>

      <StaticImage className="main-eye-catch"
        alt="Junkomoriya what's new Picture"
        src="../images/score-l.jpg"
      />
	  <p className="box-txt">守屋純子オーケストラの譜面について:＜等伯組曲＞＜家康公組曲＞に関しては、1曲6,600円にて、<a href="https://www.hustlecopy-store.com/shopbrand/ct309/">ハッスルコピーのサイト</a>で販売しております。それ以外の全ての曲（CD収録曲、非収録曲共）については、<a href="mailto:junkomry@mars.dti.ne.jp">守屋まで、直接メール</a>にて、お問い合わせください。</p>

        
      {/* <p>ハッスルコピーのサイト　：<a href="https://www.hustlecopy-store.com/shopbrand/ct309/">https://www.hustlecopy-store.com/shopbrand/ct309/</a></p> */}
      {/* <p>メールアドレス   junkomry@mars.dti.ne.jp</p> */}

            <div style={{"clear": "both"}}></div>
          </div>
        </div>
            </section>
  );
}

export default DirectSelling
