import React, { useState, useEffect } from 'react'
import  ReactModal from 'react-modal';
//import { StaticImage } from 'gatsby-plugin-image'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Modal = ({entry, isOpen, onRequestClose}) => {
  const style = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)'
    }
  };
  return (
            <ReactModal className='popup-modal mfp-hide' style={style} isOpen={isOpen} onRequestClose={onRequestClose}>
      <GatsbyImage className='scale-with-grid'
      alt={entry.name}
      src={entry.imageThumb}
      />
                <div className='description-box'>
                    <ul className="green">
	  { entry.name == 'なぜ牛丼屋でジャズがかかっているの？' ? <></> : 
                      <>
                      <li>
                        <a href="https://jmo.junkomoriya.com/?page_id=2360&ref=ns" title="CD 購入" target="new">CD 購入</a>
                      </li>
                      <li>
                        <a href={entry.info.itunesUrl} title="試聴・曲目リスト" target="new">試聴・曲目リスト</a>
                      </li>
                      </> }
                      <li>
	
                        <a href={entry.info.detailUrl} title="詳細" target="new">詳細</a>
                      </li>
                    </ul>
                    <h5>{entry.name}</h5>
                    <p>{entry.summary}</p>
                    <div className="ctitle">{entry.credit.title}</div>
                    <ul className="performers">
          {   entry.name == '守屋純子 ライブラリー' ? (
              entry.credit.performers.map(({title, url}, index) => { 
                  return (
			  <li>{ index == 0 ? title : <a href={url}>{title}</a> }</li>
                  );
              }) 
	  ) : (
                      entry.credit.performers.map((p, index) => {
                        return (
                          <li>{p}</li>
                        );
                      })
	      )
	  }
                    </ul>
                    <hr/>
          {   entry.name == '守屋純子 ライブラリー' ? <></> : 
                    <div className="cInfo">
                      <p><span>定価 : </span>{ entry.info.price }</p>
                      <p><span>{ entry.name == 'なぜ牛丼屋でジャズがかかっているの？' ? "ISBN : " :  "品番 : " }</span>{ entry.info.pid }</p>
                      { entry.name != 'なぜ牛丼屋でジャズがかかっているの？' ? <p><span>POS : </span>{ entry.info.pos }</p> : <></> }
                      <p><span>発売日 : </span>{ entry.info.releasedAt }</p>
                    </div>
          }
                    <span className='categories'>
                        <i className='fa fa-tag'/>
                    </span>
                </div>
                <div className='link-box'>
                    <a className='popup-modal-dismiss' onClick={onRequestClose}>Close</a>
                </div>
            </ReactModal>
  );
}

export default Modal
