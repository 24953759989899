// Step 1: Import React
//import * as React from 'react'
import React, { useState, useEffect } from 'react';
import { navigate } from "gatsby"
import Layout from '../components/layout'
import WhatsNew from '../components/whatsnew'
import Upcoming from '../components/upcoming'
import About from '../components/about'
import Discography from '../components/discography'
import DirectSelling from '../components/directselling'
import Footer from '../components/footer'
import { StaticImage } from 'gatsby-plugin-image'

// Step 2: Define your component
const IndexPage = () => {
  useEffect(() => {
    const len = window.location.href.length;
      if (window.location.href.indexOf('#home') !== -1 || window.location.href.indexOf('#') == -1 || window.location.href[len-1] == '#') {
        console.log('use effect in home');
        navigate('/#home');
      } 
      console.log(window.location.href.indexOf('#'));
      console.log(len);
  }, []);

  console.log('In Discography....');
  if (typeof window !== `undefined`) {
    const mq = window.matchMedia('(max-width: 767px)');
    if (mq.matches) {
      if (window.location.href.indexOf('#nav-wrap') !== -1) {
        console.log("check document...");
        console.log(document);
        if (document && document.getElementById("nav"))
          document.getElementById("nav").style.display = "block";
      }
      if (window.location.href.indexOf('#home') !== -1 ||
          window.location.href.indexOf('#whatsnew') !== -1 ||
          window.location.href.indexOf('#work') !== -1 ||
          window.location.href.indexOf('#directselling') !== -1 ||
          window.location.href.indexOf('#about') !== -1 ||
          window.location.href.indexOf('#portfolio') !== -1) {
        console.log('aaa');
        const bs = document.querySelectorAll(".mobile-btn");
        if (bs && bs.length > 1) {
          bs[0].style.display = "block";
          bs[1].style.display = "none";
          document.getElementById("nav").style.display = "none";
        }
      }
    }
  }
  return (
    <Layout pageTitle="Home">
	  {/*
      <p>I'm making this by following the Gatsby Tutorial.</p>
      <StaticImage
        alt="Clifford, a reddish-brown pitbull, posing on a couch and looking stoically at the camera"
        src="../images/clifford.jpg"
      />
	   */}
      <WhatsNew/>
      <Upcoming/>
      <Discography/>
      <DirectSelling/>
      <About/>
      <Footer />
    </Layout>
  )
}

// Step 3: Export your component
export default IndexPage
