import React, { useState, useEffect } from 'react'
import { navigate } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'

const About = () => {
  useEffect(() => {
      if (window.location.href.indexOf('#about') !== -1) {
        console.log('use effect in about');
        navigate('/#about');
      }
  }, []);
  return (
            <section id='about'>
                <div className='row'>
                    <div className='twelve columns main-col'>
                        <h2>守屋純子</h2>

      <StaticImage className="main-eye-catch"
        alt="Junkomoriya what's new Picture"
        src="../images/profile-l.jpg"
      />
                            <p>
早稲田大学“ハイソサエティーオーケストラ”でジャズを始め、ニューヨークのマンハッタン音楽院修士課程修了。アメリカ・ヨーロッパ各地で演奏活動を行う。
2000年より、“守屋純子オーケストラ”が芸術文化振興基金の助成対象事業となり、毎年定期公演を行っている。2004年、自己のカルテットでインド公演。
                            </p>
                            <p>
2005年に CD“Points Of Departure”が、第18回ミュージック・ペンクラブ賞を受賞する。同年9月、ジャズでは最も権威のあるセロニアス・モンク・コンペティション作曲部門で、東洋人としてまた女性として初優勝の栄誉に輝き、ワシントンのケネディーセンターでの授賞式に招聘されて受賞曲を演奏し、日米で話題を呼ぶ。
2008年 9月、米“モンタレージャズフェスティバル”に自己のカルテットで出演、その後、サンフランシスコ・ロサンゼルスでも公演を行う。2008・09年フランス・ツアー。2014-16年、毎年オーストラリア・パースにてビッグバンド指導。2014年より2019年まで、7回にわたり、ロシア・サンクトペテルブルク、ウラジオストクで公演。
                            </p>
                            <p>
これまでに9枚のリーダーCD（内6枚はビッグバンド作品）を発表。2018年7月、安土桃山時代の画伯＜長谷川等伯＞を題材としたジャズ組曲を収録した最新ビッグバンド作＜Art In Motion＞を発表する。
                            </p>
                            <p>
教育活動にも熱心で、 “山野ビッグバンドコンテスト”“浅草ジャズコンテスト”“ヤマハエレクトーンコンクール”等の審査員や、全国の小中高生のためのビッグバンドの指導、講演なども行なっている。2013年以降は、米国・モンタレー、オーストラリア・パースなど、海外でも学生を指導している。昭和音楽大学・尚美学園大学非常勤講師。早稲田大学エクステンションセンター講師。
                            </p>
                            <p>
                            </p>
                        <div className='row'>
                            <div className='twelve columns'>
                                <h2>Junko Moriya　　　　pianist, composer, arranger</h2>
                                <p>
Junko began studying classical piano at five years old. By age 18, she started playing jazz piano at Waseda University High Society Orchestra, one of the most famous university big bands in Tokyo. After receiving her Bachelor’s degree from Waseda University, she moved to New York where she studied with Harold Danko and received Master’s degree from the Manhattan School of Music. During that time, she performed at numerous jazz clubs around the city and in Europe as well.
                                </p>
                            <p>
Since returning to Japan in 1993, Junko has been performing at jazz clubs, concert halls and jazz festivals throughout the country, gaining recognition not only as a pianist, but also as a composer and arranger. Her big band, Junko Moriya Orchestra, regularly performs her original music, which a number of Japanese big bands have selected to include in their own repertoires. In addition to teaching jazz piano and theory at Shobi Music University and Showa Music University, Junko directs big bands at various junior high and high schools. She is also a judge of many jazz contests, and appears on radio and television programs all across Japan.
                            </p>
                            <p>
She has been named the winner of the 12th Annual BMI/Thelonious Monk Jazz Composers Competition for her work, “Playground.” The $10,000 prize was presented by BMI, and the composition was performed (with herself on piano) at the 2005 International Jazz Competition at the Kennedy Center, Washington DC. Junko is the first woman, first Japanese to receive this prestigious award.
                            </p>
                            <p>
She issued 9 CDs (6 bigbandCDs ,sxtet, octet, piano trio) as a leader, and got “2005 Japan Music Penclub Award” by her Orchestra CD “Points of Departure”. In 2008 she was invited to “the 51st Monterey Jazz Festival” with her quartet. Also she participated in “2012 Monterey Next Generation Jazz Festival” as a musical director of the student bigband. In 2004 she did a concert with her quartet in India.In 2008 and 2009 she did tours in France. She directed big band at Perth, Australia in 2014-2016, and did concert annually at St.petersburg & Vladivostok, Rossia, between 2014-2018.
                            </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
  );
}

export default About
