import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

const Upcoming = () => {
  useEffect(() => {
      if (window.location.href.indexOf('#work') !== -1) {
        console.log('use effect in upcoming schedules');
        navigate('/#work');
      }
  }, []);
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: {frontmatter: {type: {eq: "upcoming"}}}
        sort: {fields: frontmatter___date, order: ASC}
      ) {
        nodes {
          id
          slug
          frontmatter {
            title
            date(formatString: "YYYY年M月D日 hh:mm a〜")
            url
            subTitle
          }
          body
        }
      }
    }
  `)


  return (
      <section id='work'>
        <div className='row work'>
          <div className='two columns header-col'>
            <h1>
              <span>Schedule</span>
            </h1>
          </div>
          <div className='ten columns main-col'>
          {
            data.allMdx.nodes.map((node, index) => (
            <div key={index} className='row item evnt'>
              <div className='twelve columns'>
                <h3>
                    <a href={node.frontmatter.url}>{ node.frontmatter.title}</a>
                </h3>
                <h4 className='subTitle'><span>{node.frontmatter.subTitle}</span></h4>
                <h4></h4>
                <MDXRenderer>
                  {node.body}
                </MDXRenderer>
              </div>
              <hr/>
            </div>
            ))
          }
          <div className="schedule-menu-line"><p className="sched-archive"></p><p className="show-more"><a href="/sarchives/index.html">アーカイブ&gt;&gt;</a></p></div>
          </div>
        </div>
      </section>
  );
}

export default Upcoming
