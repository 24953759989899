import React from 'react'
import SocialMedia from './social_media';

const Footer = () => {
  return (
    <footer>
      <div className='row'>
        <div className='twelve columns'>
          <SocialMedia linkClass={ "social-links" } />
          <ul className='copyright'>
            <li>
              Junko Moriya Official Web Site&nbsp;
            </li>
          </ul>
        </div>
        <div id='go-top'>
          <a className='smoothscroll' title='Back to Top' href='#home'>
            <i className='icon-up-open'/>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
