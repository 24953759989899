import React, { useState, useEffect } from 'react'
import Modal from './modal'
import { navigate } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
//import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const entries = {
  "artInMotion": {
    name: "「Art In Motion」アート・イン・モーション",
    summary: "大作「長谷川等伯組曲」が収められた最新作 国宝を含む日本人に親しみ深い等伯の名画を題材にしたジャズ アートが音として動き出すという国際的にも反響が期待される野心作",
    credit: {
      "title": "Junko Moriya Orchestra / 守屋純子オーケストラ",
      performers: [
        "守屋純子: Piano,Compose,Arrange",
        "エリック・ミヤシロ、木幡光邦、奥村晶、岡崎好朗: Tp",
        "近藤和彦、緑川永徳、岡崎正典、アンディー・ウルフ、佐々木はるか: Sax",
        "佐野聡、佐藤春樹、東條あづさ、山城純子: Tb",
        "納浩一: B",
        "広瀬潤次: D",
        "岡部洋一: Perc",
      ],
    },
    info: {
      price:"2,640円（税込価格）",
      pid:"SOLJP-0016",
      pos:"4562263550976",
      releasedAt:"2018年7月18日",
      amazonUrl:"https://www.amazon.co.jp/gp/product/B07DG522ZF/ref=as_li_tl?ie=UTF8&camp=247&creative=1211&creativeASIN=B07DG522ZF&linkCode=as2&tag=ayagu-22&linkId=d2575bc34700663489e026d3a729638d",
      itunesUrl: "https://geo.itunes.apple.com/jp/album/art-in-motion/1399550808?app=itunes&at=1l3v5mX",
      detailUrl: "https://jmo.junkomoriya.com/?ref=ns&page_id=2484#content",
    }
  },
  playForPeace: {
    name: "「Play For Peace」プレイ・フォー・ピース",
    summary: "守屋純子オーケストラ3年ぶり5枚目の新作が完成！ 徳川家康公400 年祭記念組曲を中心に世界平和を祈る大作 日本を代表するオーケストラによる話題作が誕生した！！",
    credit: {
      "title": "Junko Moriya Orchestra / 守屋純子オーケストラ",
      performers: [
        "守屋純子: Piano,Compose,Arrange",
        "エリック・ミヤシロ、木幡光邦、マイケル・ブックマン、岡崎好朗: Tp",
        "近藤和彦、緑川永徳、岡崎正典、アンディー・ウルフ、宮本大路: Sax",
        "佐野聡、佐藤春樹、東條あづさ、山城純子: Tb",
        "納浩一: B",
        "広瀬潤次: D",
        "岡部洋一: Perc",
        "【録音】サウンド・シティーAスタジオ on 2015 August 12 & 13",
      ],
    },
    info: {
      price:"2,552円（税込価格）",
      pid:" SOLJP-0014",
      pos:"4562263550792",
      releasedAt:"2015年10月21日",
      amazonUrl: "https://www.amazon.co.jp/gp/product/B013K0FWIQ/ref=as_li_tl?ie=UTF8&camp=247&creative=1211&creativeASIN=B013K0FWIQ&linkCode=as2&tag=ayagu-22&linkId=a4c72126ae674f929f20ab5713f6f9f1",
      itunesUrl: "https://music.apple.com/jp/album/play-for-peace/1046754725?uo=4",
      detailUrl: "https://jmo.junkomoriya.com/?ref=ns&page_id=2482#content",
    }
  },
  "intoTheBrightDecade" : {
    name: "「Into The Bright Decade」イントゥ・ザ・ブライト・デケイド",
    summary: "10年以上に亘って培われた質の高いアンサンブル日本を代表する実力派ミュージシャン達の魅力溢れるソロ そして守屋純子の作・編曲も冴えわたる待望の新作遂に発売！",
    credit: {
      "title": "Junko Moriya Orchestra / 守屋純子オーケストラ",
      performers: [
        "守屋純子: Piano,Compose,Arrange",
        "エリック・ミヤシロ、木幡光邦、奥村晶、岡崎好朗: Tp",
        "近藤和彦、緑川永徳、小池修、アンディー・ウルフ、宮本大路: Sax",
        "片岡雄三、佐藤春樹、東條あづさ、山城純子: Tb",
        "納浩一: B",
        "大坂昌彦: D",
        "岡部洋一: Perc",
        "【録音】サウンド・シティーAスタジオ on 2015 August 12 & 13",
      ],
    },
    info: {
      price:"2,640円（税込価格）",
      pid:"SOL JP-0012",
      pos:"4562263550525",
      releasedAt:"2012年1月18日",
      amazonUrl: "https://www.amazon.co.jp/gp/product/B006DRQYVI/ref=as_li_tl?ie=UTF8&camp=247&creative=1211&creativeASIN=B006DRQYVI&linkCode=as2&tag=ayagu-22&linkId=a8283c2d1530cfa3cddfb06f2f20ffed",
      itunesUrl: "https://geo.itunes.apple.com/jp/album/into-the-bright-decade/1410196886?app=itunes&at=1l3v5mX",
      detailUrl: "https://jmo.junkomoriya.com/?ref=ns&page_id=1478#content",
    }
  },
  "threeAndFour": {
    name: "「Three And Four」スリー・アンド・フォア",
    summary: "守屋純子初のピアノトリオ作品、待望の完成！ 大胆でメロディアス、革新的な中にも伝統が息づくピアノ ニューヨークの強力メンバーを得てここに新たな感動が生まれた！",
    credit: {
      "title": "Junko Moriya Trio / 守屋純子トリオ",
      performers: [
        "守屋純子: Piano",
        "ショーン・スミス: B",
        "ビル・スチュワート: D",
        "【録音】Avatar Studio in NYC on 2009 October 28 & 29",
      ],
    },
    info: {
      price:"2,640円（税込価格）",
      pid:"SOL JP-0011",
      pos:"4562263550303",
      releasedAt:"2010年1月20日",
      amazonUrl: "https://www.amazon.co.jp/gp/product/B0030AWBEU/ref=as_li_tl?ie=UTF8&camp=247&creative=1211&creativeASIN=B0030AWBEU&linkCode=as2&tag=ayagu-22&linkId=b58604c9d02e4336cca041a1dedad1e3",
      itunesUrl: "https://geo.itunes.apple.com/jp/album/three-and-four/1410087399?app=itunes&at=1l3v5mX",
      detailUrl: "https://jmo.junkomoriya.com/?ref=ns&page_id=191#content",
    }
  },
  "groovinForward" : {
    name: "「Groovin' Forward」グルーヴィン・フォワード",
	summary: "常に前を向いて進歩し続ける守屋純子オーケストラ スタープレイヤーを集め約3年ぶりに録音された新作 日本のみならず世界へ向けて飛翔する注目作が誕生！Junko Moriya Orchestra 守屋純子オーケストラ",
    credit: {
      "title": "Junko Moriya Orchestra / 守屋純子オーケストラ",
      performers: [
      ],
    },
    info: {
      price:" 2,993円（税込価格）",
      pid:"SOL JP-0009",
      pos:"4562263550068",
      releasedAt:"2009年1月21日",
      amazonUrl: "https://www.amazon.co.jp/gp/product/B001NQCUWG/ref=as_li_tl?ie=UTF8&camp=247&creative=1211&creativeASIN=B001NQCUWG&linkCode=as2&tag=ayagu-22&linkId=489083cfe4ee39635fa8f5bd95e0c21f",
      itunesUrl: "https://geo.itunes.apple.com/jp/album/groovin-forward/1410182369?app=itunes&at=1l3v5mX",
      detailUrl: "https://jmo.junkomoriya.com/?ref=ns&page_id=200#content",
    }
  },
  "playground" : {
    name: "「Playground」 プレイグラウンド",
    summary: "セロニアス・モンク・コンポーザーズ・コンペティション優勝記念盤 ニューヨークのスター・プレイヤーとの共演により 創造力溢れ個性豊かな傑作がここに誕生",
    credit: {
      "title": "Junko Moriya Sextet 守屋純子セクステット",
      performers: [
      ],
    },
    info: {
      price:"2,640円（税込価格）",
      pid:"SOL JP-0004",
      pos:"4943566220245",
      releasedAt:"2006年12月13日",
      amazonUrl: "https://www.amazon.co.jp/gp/product/B000KCIBKO/ref=as_li_tl?ie=UTF8&camp=247&creative=1211&creativeASIN=B000KCIBKO&linkCode=as2&tag=ayagu-22&linkId=76e5ec316673a742e87840a2604c6fc3",
      itunesUrl: "https://jmo.junkomoriya.com/?ref=ns&page_id=2558",
      detailUrl: "https://jmo.junkomoriya.com/?ref=ns&page_id=202#content",
    }
  },
  "pointsOfDeparture" : {
    name: "「Points of Departure」 ポインツ・オブ・デパーチャー",
    summary: "ジャパン・ジャズ専門レーベル スパイス・オブ・ライフ・ジェー・ピー（SOL JP）の 記念すべき第一弾",
    credit: {
      "title": "Junko Moriya Orchestra / 守屋純子オーケストラ",
      performers: [
      ],
    },
    info: {
      price:"2,640円（税込価格）",
      pid:"SOL JP-0001",
      pos:"4562112361012",
      releasedAt:"2005年5月18日",
      amazonUrl: "https://www.amazon.co.jp/gp/product/B000AQDK1A/ref=as_li_tl?ie=UTF8&camp=247&creative=1211&creativeASIN=B000AQDK1A&linkCode=as2&tag=ayagu-22&linkId=d5e7f675241a8b8915e0d196c797e413",
      itunesUrl: "https://geo.itunes.apple.com/jp/album/points-of-departure/1410082410?app=itunes&at=1l3v5mX",
      detailUrl: "https://jmo.junkomoriya.com/?ref=ns&page_id=204#content",
    }
  },
  "shiftingImage" : {
    name: "「Shifting Images」 シフティング・イメージズ",
    summary: "国内最高のジャズ･ミュージシャンを一堂に集め、 2001年に録音・発表した意欲作。契約上の事情で入手不能だった貴重盤が、 ビッグバンド･ファンの熱い要望に応え再登場！",
    credit: {
      "title": "Junko Moriya Orchestra / 守屋純子オーケストラ",
      performers: [
      ],
    },
    info: {
      price:"2,095円（税込価格）",
      pid:"SOL JP-0007",
      pos:"4943566220818",
      releasedAt:"2008年1月23日",
      amazonUrl: "https://www.amazon.co.jp/gp/product/B000ZLS8X0/ref=as_li_tl?ie=UTF8&camp=247&creative=1211&creativeASIN=B000ZLS8X0&linkCode=as2&tag=ayagu-22&linkId=a0a8105422aaadb278c2e3e1f8229c35",
      itunesUrl: "https://geo.itunes.apple.com/jp/album/shifting-images/1410135386?app=itunes&at=1l3v5mX",
      detailUrl: "https://jmo.junkomoriya.com/?ref=ns&page_id=206#content",
    }
  },
  "myFavoriteColors" : {
    name: "「My Favorite Colors」 マイ・フェイヴァリット・カラーズ",
    summary: "待望の再発！[リマスター＆ボーナストラック収録] 1997年に発表した記念すべきデビュー作が再登場！",
    credit: {
      "title": "Junko Moriya Orchestra / 守屋純子オーケストラ",
      performers: [
      ],
    },
    info: {
      price:"2,095円（税込価格）",
      pid:"SOL JP-0006",
      pos:"4943566220801",
      releasedAt:"2008年1月23日",
      amazonUrl: "https://www.amazon.co.jp/gp/product/B000ZLS8WQ/ref=as_li_tl?ie=UTF8&camp=247&creative=1211&creativeASIN=B000ZLS8WQ&linkCode=as2&tag=ayagu-22&linkId=ff1877c4f2ebb392353f241b504d86de",
      itunesUrl: "https://geo.itunes.apple.com/jp/album/my-favorite-colors/1410122011?app=itunes&at=1l3v5mX",
      detailUrl: "https://jmo.junkomoriya.com/?ref=ns&page_id=208#content",
    }
  },
  "book" : {
    name: "なぜ牛丼屋でジャズがかかっているの？",
    summary: "ピアニストの活動をと通して、日常の中に見えてくるジャズの世界。ジャズの今を、自分なりの視点で、書き下ろしました。",
    credit: {
      "title": "守屋純子／著",
      performers: [
        "かもがわ出版",
      ],
    },
    info: {
      price:"1,760円（税込価格）",
      pid:"978-4-7803-0239-4",
      pos:"4562263550525",
      releasedAt:"2009年3月",
      amazonUrl: "http://www.amazon.co.jp/gp/redirect.html?ie=UTF8&location=http%3A%2F%2Fwww.amazon.co.jp%2F%E3%81%AA%E3%81%9C%E7%89%9B%E4%B8%BC%E5%B1%8B%E3%81%A7%E3%82%B8%E3%83%A3%E3%82%BA%E3%81%8C%E3%81%8B%E3%81%8B%E3%81%A3%E3%81%A6%E3%81%84%E3%82%8B%E3%81%AE-%E5%AE%88%E5%B1%8B-%E7%B4%94%E5%AD%90%2Fdp%2F4780302390%3Fs%3Dbooks%26ie%3DUTF8%26qid%3D1291033791%26sr%3D1-1&tag=ayagu-22&linkCode=ur2&camp=247&creative=1211",
      itunesUrl: "",
      detailUrl: "https://jmo.junkomoriya.com/?ref=ns&page_id=210#content",
    }
  },
};

const getImagePath = (i) => {
    return "../images/aim-s1.jpg";
}

const Entry = ({entry}) => {
  const [modalOpen, setModalOpen] = useState(false);

    console.log('Entry:')
    console.log(entry.imageThumb);
    const handleOpenModal = () => {
      setModalOpen(true);
    };

    const handleCloseModal = () => {
      setModalOpen(false);
    };

//    const imagex = getImage("../images/aim-s1.jpg");

    console.log("image: " + entry.imageThumb);
    return (
      <div className='columns portfolio-item'>
        <p>pppp</p>
        <div className='item-wrap' onClick={handleOpenModal}>
      <StaticImage className="profile-pic"
        alt="Junkomoriya what's new Picture"
        src="../images/aim-s1.jpg"
      />
          <div className='overlay'>
            <div className='portfolio-item-meta'>
              <h5>{entry.name}</h5>
              <p>{entry.category}</p>
            </div>
          </div>
          <div className='link-icon'>
            <i className='icon-down-open'/>
          </div>
        </div>
	    {/* <Modal entry={entry} isOpen={modalOpen} onRequestClose={handleCloseModal}/> */}
      </div>
    );
}


const Discography = () => {
  useEffect(() => {
      if (window.location.href.indexOf('#portfolio') !== -1) {
        console.log('use effect in disco');
        navigate('/#portfolio');
      }
  }, []);

  const [modalOpen, setModalOpen] = useState(false);
    const [modalEntry, setModalEntry] = useState({name:"", summary:"", credit:{ title:"", performers:[] }, info:"" });

    const handleOpenModal = (e, eno) => {
      setModalOpen(true);
      console.log(eno);
      let entry =  null;
      switch(eno) {
      case 1: 
        entry = entries.artInMotion;
        break;
      case 2:
        entry = entries.playForPeace;
        break;
      case 3:
        entry = entries.intoTheBrightDecade;
        break;
      case 4:
        entry = entries.threeAndFour;
        break;
      case 5:
        entry = entries.groovinForward;
        break;
      case 6:
        entry = entries.playground;
        break;
      case 7:
        entry = entries.pointsOfDeparture;
        break;
      case 8:
        entry = entries.shiftingImage;
        break;
      case 9:
        entry = entries.myFavoriteColors;
        break;
      case 10:
        entry = entries.book;
        break;
      case 11:
        entry = entries.scores;
        break;
      default:
        entry = entries.artInMotion;
        break;
      }
      setModalEntry(entry);
    };

    const handleCloseModal = () => {
      setModalOpen(false);
    };

//  const imagex = getImage("../images/aim-s1.jpg");
//  console.log(imagex);
  return (
            <section id='portfolio'>
                <div className='row'>
                    <div className='twelve columns collapsed'>
                        <h1>Discography</h1>
                        <div id='portfolio-wrapper' className='bgrid-quarters s-bgrid-thirds cf'>

      <div className='columns portfolio-item'>
          <div id="artInMotion" className='item-wrap' onClick={ (e) => { handleOpenModal(e, 1) } }>
          <StaticImage className="profile-pic"
            alt="Art In Motion Thumbnail"
            src="../images/aim-s1.jpg"
          />
          <div className='overlay'>
            <div className='portfolio-item-meta'>
              <h5>アート・イン・モーション/Art In Motion</h5>
              <p>orchestra</p>
            </div>
          </div>
          <div className='link-icon'>
            <i className='icon-down-open'/>
          </div>
        </div>
          <Modal entry={modalEntry} isOpen={modalOpen} onRequestClose={handleCloseModal}/>
      </div>

      <div className='columns portfolio-item'>
          <div className='item-wrap' onClick={(e) => { handleOpenModal(e, 2) }}>
          <StaticImage className="profile-pic"
            alt="Play For Peace Thumbnail"
            src="../images/pfp-s1.jpg"
          />
          <div className='overlay'>
            <div className='portfolio-item-meta'>
              <h5>プレイ・フォー・ピース/Play For Peace</h5>
              <p>orchestra</p>
            </div>
          </div>
          <div className='link-icon'>
            <i className='icon-down-open'/>
          </div>
        </div>
        <Modal entry={modalEntry} isOpen={modalOpen} onRequestClose={handleCloseModal}/>
      </div>

      <div className='columns portfolio-item'>
        <div className='item-wrap' onClick={(e) => { handleOpenModal(e, 3) }}>
          <StaticImage className="profile-pic"
            alt="Into The Bright Decade Thumbnail"
            src="../images/soljp_cd12_l.jpg"
          />
          <div className='overlay'>
            <div className='portfolio-item-meta'>
              <h5>イントゥ・ザ・ブライト・デケイド/Into The Bright Decade</h5>
              <p>orchestra</p>
            </div>
          </div>
          <div className='link-icon'>
            <i className='icon-down-open'/>
          </div>
        </div>
	    {/* <Modal entry={entry} isOpen={modalOpen} onRequestClose={handleCloseModal}/> */}
      </div>

      <div className='columns portfolio-item'>
        <div className='item-wrap' onClick={(e) => { handleOpenModal(e, 4) }}>
          <StaticImage className="profile-pic"
            alt="Three And Four Thumbnail"
            src="../images/soljp_cd11_l.jpg"
          />
          <div className='overlay'>
            <div className='portfolio-item-meta'>
              <h5>スリー・アンド・フォア/Three And Four</h5>
              <p>trio</p>
            </div>
          </div>
          <div className='link-icon'>
            <i className='icon-down-open'/>
          </div>
        </div>
	    {/* <Modal entry={entry} isOpen={modalOpen} onRequestClose={handleCloseModal}/> */}
      </div>

      <div className='columns portfolio-item'>
        <div className='item-wrap' onClick={(e) => { handleOpenModal(e, 5) }}>
          <StaticImage className="profile-pic"
            alt="Groovin' Forward Thumbnail"
            src="../images/soljp_cd09_l.jpg"
          />
          <div className='overlay'>
            <div className='portfolio-item-meta'>
              <h5>グルーヴィン・フォワード/Groovin' Forward</h5>
              <p>orchestra</p>
            </div>
          </div>
          <div className='link-icon'>
            <i className='icon-down-open'/>
          </div>
        </div>
	    {/* <Modal entry={entry} isOpen={modalOpen} onRequestClose={handleCloseModal}/> */}
      </div>

      <div className='columns portfolio-item'>
        <div className='item-wrap' onClick={(e) => { handleOpenModal(e, 6) }}>
          <StaticImage className="profile-pic"
            alt="Playground Thumbnail"
            src="../images/soljp_cd04_l.jpg"
          />
          <div className='overlay'>
            <div className='portfolio-item-meta'>
              <h5>プレイグラウンド/Playground</h5>
              <p>sextet</p>
            </div>
          </div>
          <div className='link-icon'>
            <i className='icon-down-open'/>
          </div>
        </div>
	    {/* <Modal entry={entry} isOpen={modalOpen} onRequestClose={handleCloseModal}/> */}
      </div>

      <div className='columns portfolio-item'>
        <div className='item-wrap' onClick={(e) => { handleOpenModal(e, 7) }}>
          <StaticImage className="profile-pic"
            alt="Points of Departure Thumbnail"
            src="../images/soljp_cd01_l.jpg"
          />
          <div className='overlay'>
            <div className='portfolio-item-meta'>
              <h5>ポインツ・オブ・デパーチャー/Points of Departure</h5>
              <p>orchestra</p>
            </div>
          </div>
          <div className='link-icon'>
            <i className='icon-down-open'/>
          </div>
        </div>
	    {/* <Modal entry={entry} isOpen={modalOpen} onRequestClose={handleCloseModal}/> */}
      </div>

      <div className='columns portfolio-item'>
        <div className='item-wrap' onClick={(e) => { handleOpenModal(e, 8) }}>
          <StaticImage className="profile-pic"
            alt="Shifting Images Thumbnail"
            src="../images/soljp_cd07_l.jpg"
          />
          <div className='overlay'>
            <div className='portfolio-item-meta'>
              <h5> シフティング・イメージズ/Shifting Images</h5>
              <p>orchestra</p>
            </div>
          </div>
          <div className='link-icon'>
            <i className='icon-down-open'/>
          </div>
        </div>
	    {/* <Modal entry={entry} isOpen={modalOpen} onRequestClose={handleCloseModal}/> */}
      </div>

      <div className='columns portfolio-item'>
        <div className='item-wrap' onClick={(e) => { handleOpenModal(e, 9) }}>
          <StaticImage className="profile-pic"
            alt="My Favorite Colors Thumbnail"
            src="../images/soljp_cd06_l.jpg"
          />
          <div className='overlay'>
            <div className='portfolio-item-meta'>
              <h5>マイ・フェイヴァリット・カラーズ/My Favorite Colors</h5>
              <p>octet</p>
            </div>
          </div>
          <div className='link-icon'>
            <i className='icon-down-open'/>
          </div>
        </div>
	    {/* <Modal entry={entry} isOpen={modalOpen} onRequestClose={handleCloseModal}/> */}
      </div>

      <div className='columns portfolio-item'>
        <div className='item-wrap' onClick={(e) => { handleOpenModal(e, 10) }}>
          <StaticImage className="profile-pic" style={{"margin-top": "25px"}}
            alt="Book Thumbnail"
            src="../images/jm_book.png"
          />
          <div className='overlay'>
            <div className='portfolio-item-meta'>
              <h5>なぜ牛丼屋でジャズがかかっているの？</h5>
              <p>book</p>
            </div>
          </div>
          <div className='link-icon'>
            <i className='icon-down-open'/>
          </div>
        </div>
	    {/* <Modal entry={entry} isOpen={modalOpen} onRequestClose={handleCloseModal}/> */}
      </div>
{/*
      <div className='columns portfolio-item'>
        <div className='item-wrap' onClick={(e) => { handleOpenModal(e, 11) }}>
          <StaticImage className="profile-pic" style={{"margin": "12px 13px"}}
            alt="Scores Thumbnail"
            src="../images/jm_score.png"
          />
          <div className='overlay'>
            <div className='portfolio-item-meta'>
              <h5>守屋純子 ライブラリー</h5>
              <p>scores</p>
            </div>
          </div>
          <div className='link-icon'>
            <i className='icon-down-open'/>
          </div>
        </div>
      </div>
*/}

                            {/* disksData.map(function (entry, index) {
                                return (
                                    <Entry key={index} index={index} entry={entry}/>
                                );
                            }) */}
                        </div>
                    </div>
                </div>
            </section>
  );
}

export default Discography
